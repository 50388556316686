/* Main Container */
.arabic-words-section {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border-radius: 8px;
  margin-top: 15vh; /* Added to move the container down */
}

/* Video animations */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Common Grid Layout - Base styles */
.grid-row {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 15px;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
}

/* Row-specific styles */
/* Labels row (1st) and Bottom Labels row (5th) */
.grid-row:first-child,
.grid-row:last-child {
  min-height: unset;
  padding: 0 0px;
  width: 100%;
}

/* Arrows rows (2nd and 4th) */
.grid-row:nth-child(2),
.grid-row:nth-child(4) {
  min-height: 80px;
}

/* Arabic words row (3rd) - tallest */
.grid-row:nth-child(3) {
  min-height: 160px;
  padding: 10px;
  align-items: center;
  width: 100%;
}

/* Grid Cell - Base styles */
.grid-cell {
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Cell styles for different rows */
/* Labels cells */
.grid-row:first-child .grid-cell,
.grid-row:last-child .grid-cell {
  padding: 0px;
  min-height: unset;
  height: 40px;
}

/* Arrow cells */
.grid-row:nth-child(2) .grid-cell,
.grid-row:nth-child(4) .grid-cell {
  padding: 5px;
  min-height: 60px;
}

/* Arabic word cells */
.grid-row:nth-child(3) .grid-cell {
  padding: 10px 5px;
  min-height: 140px;
  justify-content: center;
  gap: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Arabic Word Container */
.arabic-word-container {
  border-radius: 12px;
  padding: 10px;
  width: 85px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  min-height: unset;
  position: relative;
  margin-bottom: 15px
}
/* Background colors for each word container */
.grid-cell:nth-child(1) .arabic-word-container {
  background-color: white;
}

.grid-cell:nth-child(2) .arabic-word-container {
  background-color: white;
}

.grid-cell:nth-child(3) .arabic-word-container {
  background-color: #FFD700;  /* Yellow */
}

.grid-cell:nth-child(4) .arabic-word-container {
  background-color: #BBE3FF;  /* Light Blue */
}

/* Arabic Word Text */
.arabic-text {
  font-family: 'uth' !important;
  font-size: 2.6rem !important;
  white-space: nowrap;
  color: black;
  margin: 0;
  line-height: 1.2;
  font-weight: normal;
}

/* English Translation Container */
.english-translation-container {
  border-radius: 8px;
  padding: 5px;
  margin-top: 0;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: unset;
  height: 50px;
}

/* English Translation Text */
.english-translation-text {
  color: white;
  font-size: 0.9rem;
  text-align: center;
  white-space: normal;
  margin: 0;
  line-height: 1.2;
}

/* Green Underline */
.green-underline {
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 4px;
  background-color: #4CAF50;
  border-radius: 2px;
  z-index: 2;
}

/* Label Text Style */
.label-text {
  color: white;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  font-family: 'inter';
  margin: 0;
  line-height: 1;
  padding: 10px 0;
}

/* Arabic Word Backgrounds */
.arabic-word-first {
  font-family: 'uth' !important;
  background-color: #BBE3FF;  /* Light Blue */
  padding: 15px 25px;
  border-radius: 12px;
  font-size: 2.5rem;
}

.arabic-word-second {
  font-family: 'uth' !important;
  background-color: #FFD700;  /* Yellow */
  padding: 15px 25px;
  border-radius: 12px;
  font-size: 2.5rem;
}

.arabic-word-third {
  font-family: 'uth' !important;
  background-color: white;
  padding: 15px 25px;
  border-radius: 12px;
  font-size: 2.5rem;
}

.arabic-word-fourth {
  font-family: 'uth' !important;
  background-color: white;
  padding: 15px 25px;
  border-radius: 12px;
  font-size: 2.5rem;
}

/* Label Text Styles for each word */
.label-text-first {
  color: #BBE3FF;  /* Blue */
  text-align: center;
  font-size: 0.8rem;
  font-weight: 700;
  font-family: 'inter';
  margin-bottom: 5px;
}

.label-text-second {
  color: #FFD700;  /* Yellow */
  text-align: center;
  font-size: 0.8rem;
  font-weight: 700;
  font-family: 'inter';
  margin-bottom: 5px;
}

.label-text-third {
  color: white;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 700;
  font-family: 'inter';
  margin-bottom: 5px;
}

.label-text-fourth {
  color: white;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 700;
  font-family: 'inter';
  margin-bottom: 5px;
}

/* Video Hero Section Styles */
.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1;
}

/* Responsive Styles */
@media (max-width: 700px) {
  .grid-row {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr)); /* Force 4 columns */
    gap: 0px;
    padding: 0px;
    width: 100%;
    overflow-x: auto; /* Allow horizontal scroll if needed */
    white-space: nowrap; /* Prevent wrapping */
  }
  
  .arabic-words-section {
    padding: 0px;
    gap: 10px;
    width: 100%;
    overflow-x: hidden;
  }
  
  .arabic-text {
    height: 60px;
    font-size: 2rem !important;
  }
  
  .arabic-word-container {
    width: 70px;
    height: 70px;
  }
  
  .english-translation-text {
    font-size: 0.9rem !important;
  }
  
  /* Adjust row heights for mobile */
  .grid-row:nth-child(3) {
    padding: 0px;
    min-height: 140px;
  }
  
  .grid-row:nth-child(2),
  .grid-row:nth-child(4) {
    min-height: 60px;
    padding: 15px;
  }
  
  .grid-cell {
    min-width: 0;
    flex-shrink: 0;
  }
}

/* Hero Button Animation for Mobile */
@media (max-width: 600px) {
  .heroButtonsContainer {
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 250px;
  }
}