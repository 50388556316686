.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.border-bottom-line{
  border-bottom: solid 1px #02A723;
}

.paid-setting,
.user-info {
  display: grid;
  grid-template-columns: 200px minmax(0, 1fr);
  align-items: center;
  gap: 2rem;
  margin: 1rem 0;
  min-height: 32px;
}

.membership,
.info-heading {
  margin: 0;
  grid-column: 1;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 100%;
}

.membership-detail,
.user-detail {
  margin: 0;
  grid-column: 2;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-height: 28px;
}

.info-text {
  color: #666;
  font-size: 14px;
  line-height: 1.4;
}

.custom-switch {
  flex-shrink: 0;
}

.ant-switch {
  display: flex !important;
  align-items: center !important;
  line-height: 24px !important;
}

.ant-switch-inner {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
}

.ant-switch.custom-switch {
  margin: 0;
  transform: translateY(0);
  height: 28px;  /* Slightly taller for better proportions */
}

.ant-switch.custom-switch .ant-switch-inner {
  line-height: 24px !important;
  height: 28px;
  margin-top: -1px;
  font-size: 12px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-switch.custom-switch .ant-switch-handle {
  top: 2px;
}

.ant-switch.custom-switch .ant-switch-inner-checked,
.ant-switch.custom-switch .ant-switch-inner-unchecked {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  line-height: 1 !important;
  height: 100% !important;
  font-size: 12px !important;
  margin-top: 0 !important;
  padding-top: 2px !important;  /* Slightly adjust text position */
  font-weight: 400 !important;  /* Make text weight consistent */
}

@media (max-width: 768px) {
  .paid-setting,
  .user-info {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
  
  .membership,
  .info-heading,
  .membership-detail,
  .user-detail {
    grid-column: auto;
    padding: 4px 0;
  }

  .membership-detail {
    flex-wrap: wrap;
    row-gap: 8px;
  }

  .ant-switch.custom-switch {
    margin: 0;
  }
}

@media (max-width: 360px) {
  .membership-detail {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .ant-switch.custom-switch {
    margin-top: 4px;
  }
}

