.statistics-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.statistics-title {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.stats-section {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 30px;
}

.section-title {
  font-size: 1.5rem;
  color: #444;
  margin-bottom: 15px;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.total-count-badge {
  font-size: 0.9rem;
  font-weight: normal;
  background-color: #4CAF50;
  color: white;
  padding: 4px 10px;
  border-radius: 20px;
  margin-left: 10px;
  display: inline-block;
}

.section-count {
  font-size: 1.2rem;
  font-weight: bold;
  color: #555;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.trophy-icon {
  color: #93C47D;
  font-size: 1.2rem;
}

.words-container {
  margin-top: 15px;
  position: relative;
}

/* Custom scrollbar for webkit browsers */
.words-bubbles-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.words-bubbles-container::-webkit-scrollbar-track {
  background: transparent;
}

.words-bubbles-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.words-bubbles-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.known-words-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
  max-height: 300px;
  overflow-y: auto;
  padding: 1rem;
  scrollbar-width: thin;
  scrollbar-color: #3498db #f5f5f5;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.known-words-grid::-webkit-scrollbar {
  width: 8px;
}

.known-words-grid::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 4px;
}

.known-words-grid::-webkit-scrollbar-thumb {
  background: #3498db;
  border-radius: 4px;
}

.known-word-item {
  background-color: #e7f3ff;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  font-size: 1.2em;
  color: #333;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s;
  font-family: 'uth', 'ayat-font' !important;
  direction: rtl;
}

.known-word-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  background-color: #d0e7ff;
}

.arabic-word {
  font-family: 'uth', 'ayat-font' !important;
  font-size: 1.5rem;
  direction: rtl;
  text-align: right;
  padding: 10px 18px;
  background-color: #f0f7ff;
  border: 1px solid #d0e7ff;
}

.word-bubble:hover {
  background-color: #d0e7ff;
  transform: translateY(-2px);
}

.arabic-word:hover {
  background-color: #e0f0ff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.chart-section {
  margin-top: 2rem;
}

.chart-icon {
  color: #3498db;
  margin-left: 0.5rem;
  font-size: 1.2rem;
}

.chart-controls {
  display: flex;
  gap: 1.5rem;
  margin: 1rem 0;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  flex-wrap: wrap;
}

.control-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.control-group label {
  font-weight: 500;
  color: #666;
}

.chart-select {
  padding: 0.5rem;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  background-color: white;
  color: #333;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.chart-select:hover {
  border-color: #3498db;
}

.chart-select:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.chart-container {
  width: 100%;
  height: auto;
  min-height: 350px;
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: #ffffff;
}

/* Recharts customization */
.recharts-default-tooltip {
  background-color: rgba(255, 255, 255, 0.95) !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
  padding: 0.5rem !important;
}

.recharts-tooltip-label {
  color: #666 !important;
  font-weight: 500 !important;
  margin-bottom: 0.25rem !important;
}

.recharts-tooltip-item {
  color: #333 !important;
  padding: 0.25rem 0 !important;
}

.recharts-cartesian-axis-tick-value {
  font-size: 0.8rem !important;
  fill: #666 !important;
}

.recharts-legend-item-text {
  color: #333 !important;
  font-size: 0.9rem !important;
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.2rem;
  color: #666;
  gap: 1rem;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  min-height: 200px;
  text-align: center;
  color: #dc3545;
}

.error-message p {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.retry-button {
  background-color: #fff;
  border: 2px solid #09f;
  border-radius: 4px;
  color: #09f;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
}

.retry-button:hover {
  background-color: #09f;
  color: #fff;
}

.retry-icon {
  font-size: 1rem;
}

.no-words-message {
  text-align: center;
  padding: 30px;
  color: #777;
  font-style: italic;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px dashed #ccc;
  margin: 1rem 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .statistics-container {
    padding: 10px;
  }
  
  .stats-section {
    padding: 15px 10px;
  }
  
  .chart-container {
    min-height: 300px;
    padding: 5px;
  }
  
  .section-title {
    font-size: 1.3rem;
  }
  
  .words-bubbles-container {
    padding: 10px;
    gap: 8px;
  }
  
  .arabic-word {
    font-size: 1.3rem;
    padding: 8px 14px;
  }
  
  .section-count {
    font-size: 1.1rem;
  }
  
  /* Override max-height for mobile */
  .words-bubbles-container {
    max-height: 150px !important;
  }
  
  .chart-container {
    height: 250px;
  }
}

/* Islamic Library word styles */
.library-word-original {
  font-weight: bold;
  font-size: 18px;
  font-family: 'Amiri', 'Noto Naskh Arabic', serif;
  direction: rtl;
  margin-bottom: 6px;
}

.library-word-english {
  font-size: 14px;
  color: #666;
}

/* Adjust the known-word-item to accommodate the dual-line display */
.known-words-grid .known-word-item {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* --- Improved Styles for Updated Bar Level Progress --- */
.stats-section.level-section {
  margin-bottom: 40px;
  padding: 20px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.level-progress-bars-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.level-group {
  padding: 15px;
  background-color: #f8fafc;
  border-radius: 12px;
  transition: transform 0.2s ease;
}

.level-group-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #475569;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.level-group-title svg {
  color: #64748b;
}

.level-bars {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  height: auto;
  gap: 20px;
  padding: 0 5px;
}

.level-bar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  width: 100px;
}

.level-bar-wrapper--current {
  transform: scale(1.05);
}

.level-bar {
  width: 80px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  margin-bottom: 8px;
}

.level-number {
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.level-word-count {
  font-size: 12px;
  color: #64748b;
  margin-bottom: 5px;
}

/* --- Status Styles for Bars --- */
.level-bar--completed {
  background-color: #10b981;
  box-shadow: 0 4px 8px rgba(16, 185, 129, 0.25);
}

.level-bar--current {
  background-color: #f59e0b;
  box-shadow: 0 4px 8px rgba(245, 158, 11, 0.3);
  animation: pulseGlow 2s infinite;
}

@keyframes pulseGlow {
  0% { box-shadow: 0 0 10px rgba(245, 158, 11, 0.4); }
  50% { box-shadow: 0 0 15px rgba(245, 158, 11, 0.6); }
  100% { box-shadow: 0 0 10px rgba(245, 158, 11, 0.4); }
}

.level-bar--locked {
  background-color: #cbd5e1;
  opacity: 0.7;
}

.level-bar--locked .level-number {
  color: #64748b;
  opacity: 0.8;
}

.current-level-tag {
  display: inline-block;
  background: #f59e0b;
  color: white;
  font-size: 0.65rem;
  font-weight: 700;
  padding: 2px 6px;
  border-radius: 10px;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* --- Current Level Highlight Section --- */
.current-level-highlight {
  margin-bottom: 25px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.current-level-info {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 15px;
}

.current-level-icon {
  font-size: 1.8rem;
  color: #10b981;
  background: #ecfdf5;
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(16, 185, 129, 0.15);
}

.current-level-details {
  flex: 1;
}

.current-level-details h3 {
  margin: 0 0 5px 0;
  font-size: 1.1rem;
  color: #1e293b;
}

.current-level-name {
  color: #10b981;
  font-weight: 700;
}

.level-target {
  font-size: 0.9rem;
  color: #64748b;
}

/* --- Level Group Description --- */
.level-group-description {
  margin-top: 20px;
  text-align: center;
  padding: 0 20px;
  color: #64748b;
  font-style: italic;
  font-size: 0.95rem;
}

/* --- Current Level Indicator --- */
.level-current-indicator {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  animation: bounceUpDown 1.5s infinite;
}

@keyframes bounceUpDown {
  0%, 100% { transform: translateX(-50%) translateY(0); }
  50% { transform: translateX(-50%) translateY(-5px); }
}

/* --- Stat Cards --- */
.quran-word-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 20px;
}

.stat-card {
  flex: 1;
  min-width: 250px;
  background: #ffffff;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-card.no-icon {
  padding-left: 30px;
}

.stat-card.no-icon .stat-info {
  margin-left: 15px;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.stat-icon {
  font-size: 1.8rem;
  color: #3b82f6;
  background: #ebf5ff;
  padding: 15px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
}

.quran-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 0;
}

.known-icon {
  color: #10b981;
  background: #ecfdf5;
}

.stat-info {
  flex: 1;
}

.stat-info h3 {
  margin: 0 0 8px 0;
  font-size: 0.95rem;
  color: #64748b;
  font-weight: 600;
}

.stat-value {
  font-size: 1.8rem;
  font-weight: 700;
  color: #1e293b;
  margin-bottom: 5px;
}

.stat-percentage {
  color: #10b981;
  font-weight: 600;
  font-size: 0.9rem;
}

/* --- Level Loading --- */
.level-loading {
  height: 100px;
  min-height: unset;
}

/* --- Responsive Adjustments --- */
@media (max-width: 768px) {
  .quran-word-stats {
    flex-direction: column;
  }
  
  .current-level-info {
    flex-direction: column;
    text-align: center;
    padding: 15px;
  }
  
  .current-level-icon {
    font-size: 1.5rem;
    padding: 12px;
  }
  
  .current-level-details h3 {
    font-size: 1rem;
  }
  
  .current-level-description {
    font-size: 0.9rem;
  }
  
  .stat-card {
    padding: 20px;
  }
  
  .level-bars {
    gap: 15px;
  }
  
  .level-bar-wrapper {
    width: 80px;
  }
  
  .level-bar {
    width: 70px;
    height: 50px;
  }
  
  .level-number {
    font-size: 14px;
  }
  
  .level-word-count {
    font-size: 11px;
  }
  
  .level-group {
    padding: 15px 10px;
  }
}

@media (max-width: 480px) {
  .level-bars {
    gap: 10px;
  }
  
  .level-bar-wrapper {
    width: 65px;
  }
  
  .level-bar {
    width: 60px;
    height: 45px;
  }
  
  .level-number {
    font-size: 12px;
  }
  
  .level-word-count {
    font-size: 10px;
  }
  
  .level-group-title {
    font-size: 1rem;
  }
}
