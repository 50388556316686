@font-face {
  font-family: uth;
  src: url(./assets/Fonts/Uthmani-Hafs.woff);
}

/* Amiri font styles */
.amiri-regular {
  font-family: "Amiri", serif;
  font-weight: 400;
  font-style: normal;
}

.amiri-bold {
  font-family: "Amiri", serif;
  font-weight: 700;
  font-style: normal;
}

.amiri-regular-italic {
  font-family: "Amiri", serif;
  font-weight: 400;
  font-style: italic;
}

.amiri-bold-italic {
  font-family: "Amiri", serif;
  font-weight: 700;
  font-style: italic;
}

/* Apply Amiri font to all Arabic text in the application library components */
.book-reader-arabic *,
.library-arabic-text *,
*[dir="rtl"],
*[lang="ar"],
.MuiButton-root:has(> span:not(:empty)),
.MuiTypography-root[align="right"],
.MuiMenuItem-root[dir="rtl"],
/* Specifically target library book cards */
.MuiCard-root .MuiTypography-root,
.MuiCardContent-root .MuiTypography-root,
.MuiCardContent-root *[dir="rtl"],
.MuiCardContent-root *[lang="ar"],
/* Specifically target main typography headers in library */
.library-arabic-text .MuiTypography-root[dir="rtl"],
.library-arabic-text .MuiTypography-h5,
.library-arabic-text h5 {
  font-family: "Amiri", "Noto Naskh Arabic", serif !important;
}

/* Exclude specific components that should use system fonts */
.book-reader-arabic *[lang="en"],
.library-arabic-text *[lang="en"],
*[dir="ltr"],
body, 
button:not([dir="rtl"]) {
  font-family: inter !important;
}

.fontfamilyuth {
  font-family: uth !important;
}

.d_flex {
  display: flex;
}

/* *{
  font-family: uth !important;
} */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: inter !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Minion-font;
  src: url("./assets/Fonts/Minion Bold Condensed/rehman_font.otf");
}

@font-face {
  font-family: Minion-Italic;
  src: url("./assets/Fonts/Minion Pro Cond Italic/Minion\ Pro\ Cond\ Italic.otf");
}

@font-face {
  font-family: ayat-font;
  src: url("./assets/Fonts/scheherazade.regular.ttf");
}
@font-face {
  font-family: inter;
  src: url("./assets/Fonts/static/Inter_18pt-Regular.ttf");
}


.fs-14{
  font-size: 14px;
}

.mb-18 {
  margin-bottom: 18px;
}

/* .main-section {
  display: flex;
  height: 100%;
}

.sign-left {
  width: 100%;
  margin-top: 5rem;
} */


.main-section {
  display: flex;           
  justify-content: center; 
  align-items: center;     
  min-height: 100vh;          
  width: 100%;            
  background-color: #ffffff;
}

.sign-left {
  display: flex;           
  flex-direction: column;  
  align-items: center;     
  text-align: center;      
  padding: 2rem;           
  width: 100%;             
  background-color: #ffffff;
  max-width: 420px;
}


.nav-mobile img {
  width: 165px;
}

.sign-text {
  width: 69%;
  margin-bottom: 1.2rem;
  text-align: left;
}

.sign-text h2 {
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  color: #000;
}

.sign {
  align-items: center;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 50%; */
}
.sign-in {
  align-items: center;
  /* margin: 0px auto; */
  margin-left: 50% ;
  margin-right: 50% ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35%;
}

.center-align {
  display: flex;           /* Use Flexbox for alignment */
  justify-content: center; /* Center items horizontally */
  align-items: center;     /* Center items vertically */
  height: 100%;            /* Ensure the container has height */
}

.sign-text h2 {
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0;
}

.sign-text p {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.email-area {
  display: flex;
  gap: 28px;
  width: 72%;
  flex-wrap: wrap;
}

.email-area p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.email-area input {
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 45%;
  padding: 14px;
}

.email-area select {
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 45%;
  padding: 14px;
}

.forgot-p {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.nav-mobile {
  width: 69%;
}

.nav-mobile hr {
  background: #bcbcbc;
}

.login-btn {
  width: 50% !important;
  /* margin-left: 50% !important;
  margin-right: 50% !important; */
  height: 40px !important;
  border-radius: 25px !important;
  background: #000 !important;
  color: white !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  cursor: pointer !important;
  border: transparent !important;
  margin: 20px 0 !important;
  border-radius: 1px solid black !important;
  margin-top: 0.8rem !important;  /* Reduced from 1rem */

  &:hover {
    background-color: #fff !important;
    color: black !important;
  }
}

.login-with {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 0.6rem;
}

.login-with button {
  width: 215px !important;
  height: 46px !important;
  border-radius: 290486px !important;
  border: 1px solid #a1a9b1 !important;
  display: flex !important;
  background: #fff !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  cursor: pointer !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #050D18 !important;
}

.login-with img {
  width: 22px;
  height: 22px;
}

.sign-right {
  width: 50%;
}

.sign-right img {
  width: 66%;
  margin: 7px auto;
  height: 623px;
  display: block;
}


.First-Name input {
  width: 95%;
  padding: 10px 18px;
}


.First-Name select {
  width: 211px;
  padding: 10px 14px;
}

.create-inputs {
  display: flex;
  flex-wrap: wrap;
}

.login-create-inputs {
  display: flex;
  flex-direction: column;
  gap: 4px !important;
}

.create-text h3 {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.create-text p {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.forgot-area {
  margin: 22px auto;
  width: 72%;
  font-size: 15px;
  font-weight: 400;
}

.forgot-area p {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.OPT-boxes {
  display: flex;
  gap: 20px;
}

.OPT-boxes input {
  width: 12%;
  border-radius: 10px;
}

.landing-banner {
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
}

.landing-banner h4 {
  margin: 0;
  font-size: 59px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: Minion-Italic;
}

.landing-Boxs {
  width: 50%;
}

.landing-banner h5 {
  margin: 0;
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: Minion-Italic;
}

.landing-banner p {
  margin: 0;
  font-size: 18px;
  font-family: Minion-font;
}

.landing-banner button {
  width: 24%;
  height: 55px;
  border-radius: 42.5px;
  background: #e89b2f;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  border: transparent;
  cursor: pointer;
}

.landing-list {
  height: 70px;
  border-radius: 27px;
  background: #d9d9d9;
  width: 80%;
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 13px;
}

.landing-list img {
  width: 84px;
  height: 70px;
}

.landing-list p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px;
  margin: 0;
}

.ayat {
  cursor: pointer;
  width: 20%;
  height: 195px;
  min-width: 284px;
  max-width: 315px;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
}

.header-ayat {
  display: flex;
  justify-content: end;
  padding: 7px 22px;
  align-items: center;
}

.header-ayat img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.text-ayat {
  margin: 0;
  font-size: 50px;
  font-weight: 500;
  font-family: ayat-font;
  text-align: center;
  height: 70px;
}

#file {
  width: 100%;
  background: unset;
  height: 10px;
}

.color-div {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 0px 13px;
}

.color-div div {
  width: 42px;
  height: 13px;
  border-radius: 6.5px;
}

.end-ayat {
  padding: 0px 19px;
}

.end-ayat p {
  font-size: 15px;
  font-weight: 600;
  margin: 4px auto;
}

.end-ayat p span {
  font-size: 14px;
  font-weight: 500;
}

.footer-ayat {
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 11px;
}

.footer-ayat p {
  margin: 0;
  color: #858585;
  font-size: 16px;
  font-weight: 400;
}

.Subscription-card {
  width: 300px;
  max-width: 100%;
  min-width: 250px;
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 5px 50px 8px rgba(50, 50, 50, 0.4);
  position: relative;
  margin: 10px auto;
}

@media (min-width: 768px) {
  .Subscription-card {
    width: 300px;
  }
}

.Subscription-box h4 {
  margin: 0 auto;
}

.Subscription-box {
  margin-top: 24px;
}

.Subscription-box h5 {
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  margin: 11px 13px;
}

.Subscription-box h6 {
  color: #00a3ff;
  font-size: 20px;
  font-weight: 400;
  float: right;
  margin: 0 16px;
}

.Subscription-box p {
  color: #505050;
  font-size: 20px;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 8px;
}

.Subscription-box button {
  width: 45% !important;
  height: 40px !important;
  border-radius: 29.5px !important;
  background: black !important;
  border: transparent !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  position: absolute !important;
  bottom: 20px !important;
  left: 72px !important;
  cursor: pointer !important;
  margin-top: 0px !important;
  border: 1px solid black !important;

  &:hover {
    background-color: #fff !important;
    color: black !important;
  }
}

.UpgradePlan {
  background-image: url("./assets/UpgradePlanbg.png");
  border-radius: 24px;
  background-size: 80% 100%;
  background-repeat: no-repeat;
}

.UpgradePlan-mobile div {
  border: 1px solid;
  padding: 7px 0px 7px 10px;
  height: 55px;
  border-radius: 17px;
  width: 152px;
}

.UpgradePlan-mobile div p {
  margin: 0;
  font-size: 16px;
}

.UpgradePlan-mobile {
  display: none;
}

.ayatBanner {
  background-image: url("./assets/ayat-banner.png");
  border-radius: 63px;
  background-size: cover;
  background-repeat: no-repeat;
}

.ayat-box-bg {
  background-image: url("./assets/ayat-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 160px;
  width: 165px;
  padding: 13px;
  background-position: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ayat-box-bg2 {
  background-image: url("./assets/ayat-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 179px;
  width: 187px;
  padding: 6px 5px;
  min-width: 187px;
  background-position: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 99999;
}

.example::-webkit-scrollbar {
  display: none;
}

.example {
  height: 400px;
  overflow: scroll;
}

.example {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.example {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ContactInformation {
  margin: 32px auto;
}

.upload-img {
  width: 161px;
  height: 161px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 23px;
}


.stats-section {
  border-radius: 20px;
background: #FFF;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.12);
  padding: 40px;
  width: 100%;
}

.stats-section .stats-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #050505;
}

.stats-dropdown button#dropdown-basic {
  background: white;
  width: 104px;
  height: 30px;
  border: 1px solid #000000;
  border-radius: 8px;
  color: black;
  /* Known Words */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #454545;
}

.stats-dropdown button#dropdown-basic-time {
  background: white;
  width: 104px;
  height: 30px;
  border: 1px solid #000000;
  border-radius: 8px;
  color: black;
  /* Known Words */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #454545;
  margin-right: 10px;
}

.stats-line hr {
  background: #202020;
  margin-top: 20px;
  height: 2px;
}

.cummulative-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cummulative-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* identical to box height */

  color: #454545;
}

.surahs-learned-section {
  margin-top: 20px;
}

.surah-title {
  /* Surah's Learned */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  border-bottom: 1px solid #898989;
  padding-bottom: 5px;
}

.edit-profile-section {
  background: #ffffff;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.5);
  border-radius: 32px;
  height: 110px;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account-name {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}

.acc-name-bg {
  width: 60px;
  height: 60px;
  background: #f7f7f7;
  border-radius: 50%;
}

.edit-profile-btn {
  height: 35px;
  width: 100px;
  border: 1px solid #575757;
  border-radius: 10px;
  background: white;
  cursor: pointer;
  /* Edit Profile */
  font-weight: 400;
  font-size: 14px;
  color: #424242;
  display: flex;
  align-items: center;
  justify-content: center;
}

.points-section {
  border-radius: 20px;
background: #FFF;
box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.12);
  padding: 30px;
  margin-top: 30px;
}

.known-words-number {
  /* 0 Known Words */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #5f5f5f;
}

.daily-goal-box {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 25px;
  height: 80px;
  margin-top: 20px;
  padding: 10px 20px;
}

.daily-goal-heading {
  /* DAILY GOAL */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #313131;
}

.daily-goal-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}

.total-points {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  text-align: center;
  margin-bottom: 10px;
}

.known-words-to-level {
  font-weight: 500;
  font-size: 18px;
  color: #a9a9a9;
  text-align: center;
}

.last-week-dropdown [type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  background: none;
  /* Last Week */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #191919;
  border: none;
  margin-top: 10px;
}

.last-week-dropdown .btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background: none !important;
  /* Last Week */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #191919 !important;
  border: none;
}

.known-words-50 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-top: 10px;
}

.share-your-progress {
  /* SHARE YOUR PROGRESS */
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #000000;
  text-transform: uppercase;
  text-align: center;
  margin-top: 30px;
}

.badges img {
  width: 100px;
}

.followers-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #000000;
  margin-left: 20px;
  margin-top: 20px;
}

.followers-img img {
  width: 46px;
  height: 46px;
  border: 1px solid #000000;
  border-radius: 50%;
}

.hover-text-box {
  background-image: url("../src/assets/image\ 27.png");
  background-repeat: no-repeat;
  height: 50px;
  position: relative;
}


.circular-progress .CircularProgressbar .CircularProgressbar-path {
  stroke: #02A723;
}

.progress-content img {
  width: 75px;
  margin: 0 auto;
  display: block;
}

.progress-content .link-text {
  /* L I N K Q U R A N */

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 2px;

}

.community-search img {
  position: absolute;
  height: 25px;
  right: 10px;
}

.community-search {
  display: flex;
  align-items: center;
  margin-left: 25px;
  position: relative;
  width: 300px;
}

.community-search input {
  position: relative;
  width: 100px;
  box-sizing: border-box;
  width: 300px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #CFCFCF;
  border-radius: 4px;
  outline: none;
  padding: 0 35px 0 10px;
}

.community-wall h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  color: #000000;
  margin-left: 10px;
}

.community-wall-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.5);
  border-radius: 44px;
  padding: 35px 35px 35px 35px;
  display: flex;
  gap: 25px;
}

.wall-profile {
  width: 55px;
  height: 55px;
  background: #FBB758;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #000000;
}

.community-wall-card textarea {
  outline: none;
  box-sizing: border-box;
  width: 100%;
  height: 250px;
  border: 1px solid #000000;
  border-radius: 44px;
  padding: 15px 15px 15px 25px;
  font-size: 18px;
  font-weight: 400;
  color: #4D4D4D;
  resize: none;
}

.community-section {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.5);
  border-radius: 44px;
  padding-bottom: 50px;
  /* height: 465px; */
}

.community-follow {
  box-sizing: border-box;
  position: absolute;
  width: 100px;
  height: 40px;
  right: 40px;
  top: 20px;
  background: #FFFFFF !important;
  border: 1px solid #000000 !important;
  border-radius: 8px;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #000000 !important;
}

.community-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.community-content img {
  width: 110px;
  height: 110px;
  margin: 20px 0 0 0;
  cursor: pointer;
}

.community-content h3 {
  font-size: 26px;
  color: #000000;
  font-weight: 700;
  margin-top: 20px;
}

.community-content p {
  font-size: 22px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
}

.community-content p span {
  font-weight: 600;
  margin-left: 15px;
}

.community-text {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 150px;
  height: 45px;
  background: rgba(237, 175, 89, 0.49);
  border-radius: 37.5px;
  padding: 0 0 0 7px;
}

.community-text img {
  width: 40px;
  height: 40px;
}

.community-text p {
  font-size: 20px;
  color: #000000;
  font-weight: 600;
}

.community-description {
  /* margin-top: 10px; */
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  color: #4D4D4D;
}

.community-goal {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.5);
  border-radius: 44px;
  padding-top: 30px;
  /* height: 470px; */
}

.arabic-words {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 30px;
}

.arabic-words img {
  width: 40px;
  height: 40px;
}

.arabic-words p {
  font-size: 18px;
  font-style: normal;
  color: #3E3E3E;
  margin: 0;
  font-weight: 600;
}

.arabic-words-member {
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  margin: 0;
}

.goal-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 25px 8px 25px;
  width: 78%;
}

.goal-selection select {
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.goal-selection h6 {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.days-progress-bar .circular-progress .CircularProgressbar .CircularProgressbar-path {
  stroke: #ffffff;
}

.progress-days {
  font-size: 12px;
}


.community-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 35px 0 35px;
  padding-right: 40px;
  width: 87%;
  padding-left: 0px;
}


.progress-text {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  width: 15%;
}

.progress-bar-community p {
  font-size: 16px;
  font-weight: 600;
  color: #626262;
  text-transform: capitalize;
}

.progress-bar-community1 {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  gap: 10px;
}

/* .community-progress progress {
  
  margin-left: 20px;
} */

.community-progress progress::-webkit-progress-value {
  background-color: #000000;
}

.progress-bar-community div {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  color: #626262;
  text-transform: uppercase;
}

.progress-bar-community progress{
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 20px;
}

.community-coins {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #626262;
  margin-top: 5px;
  padding-bottom: 20px;
}

.community-coins1 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000;
  margin-top: 5px;
  padding-bottom: 20px;
  margin-left: 10%;
}

.bar-chart {
  height: 223px;
  display: flex;
  justify-content: center;
}



.badges-section {
  height: 400px;
  border-radius: 20px;
background: #FFF;
box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.12);
  margin-top: 30px;
  padding: 20px 40px;
}

.badges-body {
  height: 253px;
  overflow-y: scroll;
}




.badge-name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #191919;
  margin-left: 10px;
}

.badges-section img {
  width: 80px;
}


.badge-line {
  margin-bottom: 15px;
}


.new-landing-banner {
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-landing-banner .quran-img {
  width: 300px;
  margin-top: 10px;
}

.new-landing-banner .bismillah-img {
  width: 200px;
  margin-top: 20px;
}


.new-landing-banner .banner-text {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #454545;
  margin-top: 20px;


}

.new-landing-banner .new-banner-btn {
  background: #000000;
  border-radius: 42.5px;
  border: 1px solid black;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
  padding: 10px 25px;
  margin-top: 20px;

  &:hover {
    background-color: #ffff;
    color: black;
  }
}


.language-section {
  background: #000000;
  padding: 20px 0 0 0;
  width: 100%;
}


.language-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
  padding-bottom: 20px;

}


.new-position-ayat {
  padding-top: 40px;
}


.country-input .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 47px !important;
}

.country-input .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-segi59 {
  height: 47px !important;
}

.green-tick {
  width: 50px;
  height: 50px;
  background: #D9D9D9;
  border-radius: 50%;
  transition: 0.3s ease-in;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.green-tick_1 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.green-tick:hover {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 0.3s ease-in;
  background: #01c51195;
  box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.24), 0 5px 11px 0 rgba(0, 0, 0, 0.19);
  transition-duration: 0.5s;
}

.green-tick:hover path {
  fill: white;
}


.learn-hover:hover {
  background: #fcd966 !important

}
.new-hover:hover {
  background: #2FB0E847 !important;
}
.known-hover:hover {
  background: #93C47D !important;
}
.recall-hover:hover {
  border: 1px solid #9ef57c !important;
}

.logout-btn-hover:hover {
  background-color: #000000 !important;
  color: #fff !important;
}

.sidebar-TextArea:hover {
  color: #fff !important;
  background: #E89B2F !important;
}

.sidebar-TextArea:hover img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.admin-user {
  align-items: center;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 30px 0 #0000001a;
  /* display: flex; */
  height: auto;
  margin: 19px auto;
  /* padding: 35px 5px 9px 9px; */
  width: 100%;
  min-width: 900px;
}

.admin-user-chart {
  align-items: center;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 30px 0 #0000001a;
  /* display: flex; */
  height: auto;
  margin: 30px 0;
  /* padding: 35px 5px 9px 9px; */
  width: 95%;
  padding: 48px 22px;
}

.admin-user p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 0.14994px;
  color: #979797;
}

.admin-user label {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 14px;
  letter-spacing: 0.14994px;
  color: #979797;
}

.user-profile-name {
  display: flex;
  /* justify-content: center; */
  gap: 11px;
  margin-left: 19px;
  width: 40%;
}

.user-profile-name img {
  width: 35px;
}

.plan-btn {
  background: #E89B2F !important;
  border-radius: 18px !important;
  color: #fff !important;
  width: 70% !important;
  padding: 10px 0px;
  margin: 0 !important;
}

.font-user {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.14994px;
  color: rgba(0, 0, 0, 0.87);
}

.searchbar-responsive input {
  width: 100% !important;
}

.search-bar-nav3 {
  display: flex;
  color: black;
  align-items: center;
  gap: 24px;
  width: 35%;
}

.recharts-legend-wrapper {
  width: 100% !important;
}

.user-reponsive {
  margin-left: 20% !important;
  margin-top: 83px !important;
}

.main-pages-reponsive {
  /* margin-left: 20% !important; */
  margin-top: 83px !important;
}

.hamburgerImg-nav2 {
  display: none;
}



.green-tick_1 {
  transition: 0.3s ease-in;
  background: #01c51195;
}

.green-tick_1 path {
  fill: white;
}

/* 
  .num-hover:hover{
    background: #adadad55;
  } */

.sidebar-TextArea img {
  width: 18px !important;
}

.mobile-view-pages {
  margin-top: 0px !important;
}




.showsidebar {
  display: block !important;
  transform: translateX(0) !important;
}

.displaynone {
  display: none !important;
}

/* Make sure AdminSidebar is properly styled for all views including BookReader */
.AdminSidebar-responsive {
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

@media only screen and (max-width: 992px) {
  .AdminSidebar-responsive {
    display: none;
    top: 10.3% !important;
  }
  
  .AdminSidebar-responsive.showsidebar {
    display: block !important;
  }
}

.iconsDiv {
  padding: 3px;
  border-radius: 8px;
  height: 35px;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
}

.verticalLine {
  width: 2px;
  background-color: #CCCCCC;
  height: 26px;
  margin-top: 4px;
}

.arrows-css {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.payment_img1 {
  width: 150px;
  cursor: pointer;
}

.payment_img2 {
  width: 100px;
  cursor: pointer;

}

.payment_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  gap: 80px;
}

.payment_active {
  box-shadow: 2px 1px 10px 10px #0000000d;
}

.sub_text_payment {
  width: 50%;
  display: block;
  margin: 0 auto;
  padding: 40px;
}

.card_number {
  width: 100%;
  padding: 8px;
  border: solid 1px #d1d1d1;
}

.text_payment {
  font-size: 20px;
  font-weight: 600;
  color: #767676;
}

.payment_sub_input {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 15px;
}

.Pay_now_btn {
  background-color: #000 !important;
  color: #fff !important;
  padding: 12px;
  width: 55%;
  font-size: 20px;
  display: block;
  margin: 0 auto;
  border-radius: 5px;
  margin-top: 65px !important;
}

.profile_btn_design {
  border: solid 1px #000 !important;
  padding: 5px 20px !important;
  color: #000 !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  font-size: 15px !important;
}

.Jbelly_txt {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0;
}

.main_followes_box {
  display: flex;
  align-items: center;
  gap: 25%;
  margin-bottom: 20px;
}

.flex_with_gap {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 12px;
  width: 40%;
  cursor: pointer;
}

.nav2-left-area {
  display: flex;
  color: black;
  align-items: center;
  gap: 24px;
}



.displaynone {
  display: none;
}

span.MuiSwitch-thumb.css-jsexje-MuiSwitch-thumb {
  color: #fba834 !important;
}
.switch-thumb-plan span.MuiSwitch-thumb.css-jsexje-MuiSwitch-thumb {
  color: white !important;
  box-shadow: none !important;
}
/* span.MuiSwitch-track.css-1yjjitx-MuiSwitch-track{
    background-color: #e9c087 !important;
  } */
.css-ob1t9r-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #e9c087 !important;
}

.update_btn {
  position: relative;
  float: right;
  right: 10%;
  margin-top: 30px;
  border: solid 2px #000;
  border-radius: 12px;
  padding: 5px 30px;
  font-size: 17px;
}

.MuiModal-root.css-79ws1d-MuiModal-root {
  overflow: scroll !important;
}

.css-ob1t9r-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #e9c087 !important;
}

.translator-text {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  width: 80%;
  margin: 36px auto 0;
  line-height: 1.6;
  /* letter-spacing: 2px; */

}

.Known_word {
  width: 50px;
  height: 50px;
  background-color: #d9d9d9;
  border-radius: 40px;
  text-align: center;
  color: #fff;
  padding-top: 7px;
  font-weight: bold;
  font-size: 23px;
  margin-left: 15px;
  cursor: pointer;
}

.Known_word:hover {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 0.3s ease-in;
  background: #01c51195;
  box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.24), 0 5px 11px 0 rgba(0, 0, 0, 0.19);
  transition-duration: 0.5s;
}

.margin_top_3 {
  margin-top: 3px;
}

.checkinput_check {
  display: flex;
  margin-top: -22rem;
  justify-content: center;
  align-items: center;
  padding-left: 22%;
  /* width: 100%; */
}

.bismillah {
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  font-family: "uth";
  margin: 0;
  padding: 0 10px;
}

.Surah-h5-hover:hover {
  cursor: pointer;
  background-color: #76cdff2e;
  border-radius: 5px;
}

.login-with button {
  margin-top: 0;
}

.Btns-hover-nav:hover {
  box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.24), 0 5px 11px 0 rgba(0, 0, 0, 0.19);
  transition-duration: 0.5s;
}
.ml-1{
  margin-left: 5px;
}
.update-button{
  background-color: #000 !important;
  color: #fff !important;
}
.btn-hover:hover {
  background-color: #000 !important;
  color: #fff !important;
  transition: 0.5s;
  border: 1px solid #fff !important;
  box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.24), 0 5px 11px 0 rgba(0, 0, 0, 0.19);
}

.dashboard-btn-hover:hover {
  box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.24), 0 5px 11px 0 rgba(0, 0, 0, 0.19);
  transition: 0.5s;
  color: #e89b2f !important;
  border: 1px solid #e89b2f !important;
}

.paypal-button-container.paypal-button-layout-vertical.paypal-button-shape-pill.paypal-button-number-multiple.paypal-button-env-sandbox {
  display: block !important;
  margin: 0 auto !important;
}

fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
  padding-top: 10px;
}

.handleChange {
  padding: 5px;
  width: 235px;
  border-bottom: none;
  border-radius: 10px 10px 0px 0px;
  border: solid 1px #adadad;
}

.All-section {
  display: flex;
  align-items: baseline;
  margin-left: 12%;
}

.update-btn-sub {
  background-color: #e89b2f;
  color: #fff;
  padding: 7px 20px;
  border-radius: 10px;
}

.login-hover:hover {
  background-color: #e89b2f !important;
  color: #fff !important;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
}

button {
  cursor: pointer !important;
}

.ayat_bg_hover:hover {
  background-color: #edf9ff !important;
}

fieldset.MuiOutlinedInput-notchedOutline.css-igs3ac {
  border: none !important;
}

.MuiModal-root.css-8ndowl {
  overflow-y: scroll !important;
}

.hover_overicon:hover {
  background-color: #edf9ff !important;
}

.know-btn {
  width: 60%;
}

.btn-in-mobile-view {
  display: flex;
  width: fit-content;
  flex: 1;
  justify-content: space-between;
}

.button-width {
  width: 190px;
}

@media only screen and (max-width: 1224px) {
  .user-responsive {
    overflow: scroll !important;
  }

  .chart-resposive {
    width: 100% !important;
  }

  .sidebar-TextArea {
    font-size: 14px !important;
    min-width: 185px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .Contact {
    left: 330px !important;
  }
}

@media only screen and (max-width: 1090px) {
  .Contact {
    left: 300px !important;
  }
}

@media only screen and (max-width: 992px) {
  .admin-user {
    width: 95% !important;
  }

  .Logout {
    width: 44% !important;
  }
  
  .Contact {
    left: 240px !important;
    width: 50% !important;
  }

  .AdminSidebar-responsive {
    display: none;
    top: 10.3% !important;
  }

  .user-reponsive {
    margin-top: 100px !important;
    margin-left: 0 !important;
    width: 100% !important;
  }

  .main-pages-reponsive {
    margin-top: 100px !important;
    margin-left: 0 !important;
    width: 100% !important;
  }

  .search-bar-nav3 {
    width: 60%;
  }

  .pageview-responsive {
    flex-direction: column !important;
  }

  .responsive-width {
    width: 90% !important;
    margin: 11px auto !important;
  }

  .account-name {
    font-size: 16px !important;
  }

  .mobile-view-pages {
    display: block !important;
  }

  .stats-section-main {
    width: 100% !important;
  }

  .edit-profile-section-main {
    width: 100% !important;
    margin-top: 40px !important;
  }
  .voiceSection {
    width: 100% !important;
    min-height: 252px !important;
  }
  .voiceDiv {
    padding: 10px 0px !important;
    gap: 0 !important;
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 890px) {
  .Contact {
    left: 200px !important;
    width: 50% !important;
  }

  .upload-img {
    width: 110px;
    height: 110px;
  }

  .profile-textarea {
    width: 400px !important;
    height: 150px !important;
  }

  .profile-img-main-box {
    width: 200px !important;
    height: 160px !important;
  }
  .voiceSection {
    width: 100% !important;
    min-height: 252px !important;
  }
  .voiceDiv {
    padding: 10px 0px !important;
    gap: 0 !important;
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  /* Hide Progress link and Instructions button on smaller screens */
  .progress-nav-link,
  .hide-on-mobile {
    display: none !important;
  }

  .sign-right {
    display: none;
  }

  .First-Name {
    width: 100% !important;
  }

  .edit-btn-reponsive {
    width: 38% !important;
  }

  .First-Name input {
    width: 100% !important;
  }

  .First-Name select {
    width: 100% !important;
  }

  .login-btn {
    width: 53% !important;
  }

  .create-text h3 {
    font-size: 19px !important;
  }

  .create-text p {
    font-size: 14px !important;
  }

  .sign-text h2 {
    font-size: 22px !important;
    margin-bottom: 20px !important;
  }

  .sign-right {
    display: none;
  }

  .sign-left {
    width: 100%;
  }
  .sign {
    width: 100%;
  }
  .sign-in {
    width: 100%;
  }
  .nav2-left-area a {
    display: none;
  }

  .main_followes_box {
    gap: 10% !important;
  }

  .sub_text_payment {
    width: 100% !important;
  }

  .payment_box {
    gap: 33px;
  }

  .mobile-view-pages {
    margin-top: 0;
  }
  .hero-mobile-view {
    margin-top: 0px !important;
    flex-direction: column;
  }

  .Contact {
    left: 50px !important;
    top: 170px !important;
    width: 80% !important
  }

  .Logout {
    width: 80% !important;
  }

  .hamburgerImg {
    margin-left: 111px;
  }

  .hamburgerImg-nav2 {
    margin-left: 18px;
    display: block;
  }

  .searchbar-responsive {
    display: none !important;
  }

  .search-bar-nav3 {
    width: 80%;
  }

  .SentenceView-Scroll {
    overflow: scroll !important;
  }

  .notification {
    width: 80% !important;
    top: 130px !important;
  }

  .ranking-searchbar {
    margin-left: 11px !important;
  }

  .ranking-buttons {
    width: 100% !important;
    padding: 6px !important;
  }

  .ranking-button1 {
    font-size: 13px !important;
    padding: 6px !important;
    width: 24% !important;
  }

  .ranking-button2 {
    font-size: 13px !important;
    padding: 6px !important;
    width: 36% !important;
  }

  .ranking-button3 {
    font-size: 13px !important;
    padding: 6px !important;
    width: 30% !important;
  }

  .responsive-text {
    width: 52% !important;
  }

  .flexText {
    justify-content: center !important;
    flex-wrap: wrap !important;
    margin-bottom: 20px;
  }

  .nav2-mobile {
    display: none !important;
  }

  .landing-banner h4 {
    font-size: 24px;
  }

  .UpgradePlan-mobile {
    background-color: #000;
    height: 250px;
    width: 70%;
    margin: 0 auto;
    display: flex;
    gap: 18px;
    justify-content: center;
    gap: 12px;
    align-items: center;
    flex-direction: column;
    border-radius: 26px;
  }

  .UpgradePlan-mobile button {
    border: 1px solid !important;
    height: 52px !important;
  }

  .landing-banner h5 {
    font-size: 24px !important;
  }

  .landing-banner p {
    font-size: 14px !important;
  }

  .landing-banner button {
    width: 60% !important;
    height: 45px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  .landing-img {
    display: none;
  }

  .landing-Boxs {
    width: 100% !important;
  }

  .landing-list {
    margin: 0 auto;
    gap: 8px !important;
    width: 90%;
  }



  .landing-list p {
    font-size: 13px !important;
  }

  .connecth3-responsive {
    font-size: 22px !important;
  }

  .connectp-responsive {
    font-size: 18px !important;
  }

  .ayatBannerbutton {
    width: 137px !important;
  }

  .ayatBannerh4 {
    font-size: 61px !important;
    margin-top: 37px !important;
  }

  .ayat-boxs-area {
    justify-content: center;
  }

  .footer-logo {
    margin: 0 auto !important;
    display: block !important;
  }

  .landing-list img {
    width: 55px !important;
    height: 50px !important;
  }

  .foot-media {
    margin: 7px 33px;
    display: flex;
    justify-content: center;
  }

  .landing-banner {
    background-position: right;
  }

  .footer-textarea {
    width: 80% !important;
    text-align: center !important;
    margin: 50px auto !important;
    float: none !important;
  }

  .CopyrightArea {
    width: 80% !important;
    margin: 70px auto 0px !important;
    text-align: center !important;
  }

  .CopyrightArea p {
    font-size: 12px !important;
  }

  .Subscription-card {
    display: block;
  }

  /* .subscription-card {
    height: 400px;
  } */

  .header-Subscription {
    height: 170px;
  }

  .header-Subscription h3 {
    font-size: 35px;
  }

  .Subscription-box h5 {
    font-size: 24px;
    margin: 9px 13px;
  }

  .Subscription-box p {
    font-size: 18px;
    margin-top: 16px;
  }

  .Subscription-box h6 {
    font-size: 18px;
  }

  .Subscription-box button {
    position: relative;
  }

  .search-progressbar {
    flex-direction: column;
    gap: 14px;
  }

  .search {
    justify-content: center;
    width: 100% !important;
    align-items: center;
  }

  .css-1v5z18m {
    width: 150px !important;
  }

  .progress-bar-pages .css-1v5z18m {
    width: 226px !important;
  }

  .UpgradePlan {
    display: none !important;
  }
  .voiceSection {
    width: 100% !important;
    min-height: 252px !important;
  }
  .voiceDiv {
    padding: 10px 0px !important;
    gap: 0 !important;
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .css-84nhb1-MuiAutocomplete-root {
    width: 100% !important;
    margin-top: 30px;
  }

  .country-input input {
    width: 100% !important;
  }

  .profile-field-responsive {
    justify-content: center !important;
  }

  .profile-textarea-main {
    width: 100% !important;
    padding: 15px !important;
  }

  .profile-textarea {
    width: 100% !important;
    height: 120px !important;
  }

  .country-input {
    flex-direction: column;
  }

  .badge-name {
    font-size: 12px !important;
  }

  .badges-section img {
    width: 45px !important;
  }

  .badges-section {
    padding: 20px 15px !important;
  }

  .badges-heading {
    font-size: 17px !important;
    margin-bottom: 20px !important;
  }

  .days-progress-bar {
    gap: 11px !important;
  }

  .badges img {
    width: 50px !important;
  }

  .known-words-50 {
    font-size: 12px !important;
  }

  .known-words-to-level {
    font-size: 16px;
  }

  .total-points {
    font-size: 20px !important;
  }

  .account-responsive {
    padding: 33px 15px !important;
  }

  /* .responsive-text {
    width: 40% !important;
  } */

  .ranking-responsive {
    padding: 0px 30px;
  }

  .ranking-main-box {
    width: 100% !important;
    padding: 15px 10px !important;
  }

  .ranking-responsive p {
    font-size: 16px !important;
  }
  .know-btn {
    width: fit-content;
    margin: auto;
  }
  .btn-in-mobile-view {
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  .button-width{
    width: unset !important;
  }
  .voiceSection {
    width: 100% !important;
    /* height: 252px !important; */
  }
  .voiceDiv {
    padding: 10px 0px !important;
    gap: 0 !important;
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 550px) {
  .voiceSection {
    width: 100% !important;
  }

  .voiceDiv {
    padding: 10px 0px !important;
    gap: 0 !important;
    margin-top: 0 !important;
  }

  .voiceDiv h4 {
    font-size: 35px !important;
  }

  .voiceDiv span {
    font-size: 14px !important;
  }

  .pageview-responsive.mobile-view-pages {
    align-items: center;
  }

  .modal-6-trigger.btn-hover {
    margin-top: 20px !important;
  }

  .page-view-last-box {
    align-items: center !important;
    justify-content: center !important;
    /* flex-direction: column; */
    gap: 16px;
    margin: 54px auto 0px !important; 
  }

  .page-view-last-box div {
    display: flex !important;
    align-items: center !important;
    justify-content: start;
    gap: 11px;
    width: unset !important;
  }
  .btn-gap {
    gap: 36px !important
  }

  .modal-7-trigger {
    flex-direction: column;
    gap: 36px;
  } 

  /* .page-view-last-box div img {
    height: 36px !important;
  } */
  .page-view {
    height: 45px !important;
  }
  .page-view-image {
    width: 45px !important;
  }
  .know-btn {
    width: fit-content;
    margin: auto;
  }
  .btn-in-mobile-view {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button-width{
    width: unset !important;
  }
}

@media only screen and (max-width: 499px) {
  .arrow-responsive {
    transform: translate(0px, 5px) !important;
  }

  .upload-img {
    width: 90px;
    height: 90px;
  }

  .profile-img-main-box {
    width: 160px !important;
    height: 140px !important;
  }

  .profile-img-main-box-responsive {
    width: 100%;
    display: contents;
  }

  .profile-main-responsive-section {
    padding: 33px 15px !important;
  }

  .Contact {
    left: 30px !important;
    top: 170px !important;
    width: 85% !important;
  }

  .contact-input input {
    width: 65% !important;
  }
  .know-btn {
    width: fit-content;
    margin: auto;
  }
  .btn-in-mobile-view {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button-width{
    width: unset !important;
  }
}

@media only screen and (max-width: 480px) {
  .mobile-view-pages-main {
    padding: 33px 10px !important;
  }

  .play-button img {
    height: 45px !important;
  }

  .sentence-view img {
    width: 45px !important;
  }

  .sentence-view p {
    font-size: 16px !important;
  }

  .modal-6-trigger.btn-hover {
    /* width: 165px !important; */
    width: fit-content !important;
    white-space: nowrap;
  }
  .know-btn {
    width: fit-content;
    margin: auto;
  }
  .btn-in-mobile-view {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button-width{
    width: unset !important;
  }
  .sentence-view-main {
    display: flex;
    align-items: center !important;
  }
  .btn-gap {
    gap: 24px !important
  }
}
svg.MuiCircularProgress-svg.css-1idz92c-MuiCircularProgress-svg{
  color: rgb(237, 175, 89) !important;
}



/* Hide the modal on larger screens */
.modal-only {
  display: none;
}

/* Hide the div on smaller screens */
.div-only {
  display: none;
}

/* Hide the modal on larger screens */
.modal-only {
  display: none;
}

/* Hide the div on smaller screens */
.div-only {
  display: none;
}

/* Show the modal on screens up to 991px */
@media (max-width: 991px) {
  .modal-only {
    display: block;
  }
  .div-only {
    display: none;
  }
}

/* Show the div on screens larger than 991px */
@media (min-width: 992px) {
  .modal-only {
    display: none;
  }
  .div-only {
    display: block;
  }
}


/* FONT-SIZES */
.fs-14 {
  font-size: 14px;
}

/* margins */
.mb-18 {
  margin-bottom: 18px;
}

/* Pagination */
.css-wjh20t-MuiPagination-ul {
  justify-content: end;
}

.last-week-dropdown [type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  margin-top: 0;
}

/* Voice Section */
.meaning-text {
  margin-top: 12px;
  margin-bottom: 0px;
  text-align: center;
}

.meaning-text p {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #338FFF;
}

.header-subscription {
  width: 100%;
  background-color: #000000;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-subscription h3 {
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  margin-bottom: 0;
}

.subscription-card {
  width: 20%;
  /* height: 580px; */
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 5px 50px 8px rgba(50, 50, 50, 0.4);
  position: relative;
  padding-bottom: 22px;
  margin-top: -10px;
  zoom: 0.8;
}

.all-access {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20px;
  text-align: center;
  margin-top: 0 !important;
}

.value {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px;
  text-align: left;
  color: #000000 !important;
  margin: 0 !important;
}

.card-btn.custom_{
  position: static !important;
  border-radius: 12px !important;
  /* margin-top: 22px !important; */
}

.card-btn.amount-select {
  position: static !important;
  color: #000000 !important;
  border: 1px solid #CCCCCC !important;
  border-radius: 6px !important;
  background-color: white !important;
  font-size: 14px !important;
}
.card-btn.border-color {
  border: 1px solid #E89B2F !important;
}

.select-option {
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 12px;
  margin: auto;
  width: fit-content;
}

.full-access {
  text-align: center !important;
  margin-top: 8px !important;
  font-size: 16px !important;
  color: #000000 !important;
  margin-left: 8px !important;
}


input[type="radio"]{
  accent-color: rgb(186 139 0);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-field {
  border: 1px solid #CCCCCC;
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  border-radius: 6px;
}

.input-field::placeholder {
  color: #888;
  font-size: 12px;
  text-align: center;
}

/* payment summary card */


.payment-subscription-card {
  width: 20%;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1.31px 7.87px 0px #00000029;
  min-width: 250px;
  /* position: relative; */
  padding-bottom: 24px;
  margin: auto;
}

.payment-header-subscription {
  width: 100%;
  background-color: #000000;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-button {
  width: 45% !important;
  height: 40px !important;
  border-radius: 29.5px !important;
  background: black !important;
  border: transparent !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  bottom: 20px !important;
  left: 72px !important;
  cursor: pointer !important;
  margin-top: 0px !important;
  border: 1px solid black !important;

  &:hover {
    background-color: #fff !important;
    color: black !important;
  }
}
.custom-pay-pal-button {
  padding: 10% 0;
  width: 50%;
  display: block;
  margin: 0 auto;
  
}
.middle-border {
  border-right: 1px solid #7B7B7B
}
@media only screen and (max-width: 1024px) {
  .custom-pay-pal-button {
    padding: 10% 0;
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  .middle-border {
    border-right: none
  }
}



.custom-heading {
  font-size: 30px;
  font-family: 500;
  text-align: center;
  margin-bottom: 55px;
}
.payment-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media only screen and (max-width: 1024px) {
  .payment-main {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
}

.MuiTypography-root {
 font-family: inter !important;
}

/* Arabic text specific styling only for BookReader */
.book-reader-arabic [dir="rtl"] *, .book-reader-arabic .arabic-text {
  font-family: 'Noto Naskh Arabic', serif !important;
}

.account-heading {
  font-family: inter !important;
  font-size: 30px;
  font-weight: 600;
}
.starter-main {
  width: 100%;
  padding-top: 4rem;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 4rem;
}
.user-info {
  width: 40%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  margin-top: 0.5rem;
  align-items: center;
}
@media (max-width: 600px) {
  .user-info{
    width: 100%;
    }
}
.paid-setting {
  width: 59%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  margin-top: 0.5rem;
  align-items: center;
  gap: 1rem;
}
.info-heading {
  font-size: 16px;
  font-weight: 600;
  color: black;
  margin-top: 1rem;
}
.user-detail {
  font-size: 14px;
  font-weight: 500;
  color: #393d40;
  margin-top: 1rem;
}
.membership {
  font-size: 14px;
  font-weight: 400;
  color: black;
}
.membership-detail {
  font-size: 14px;
  font-weight: 500;
  color: #8c8c8c
}
.info-text {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-top: 1rem;
}
.info-texts {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-top: 1rem;
}
.update-btn {
  /* width: 171px !important;
  height: 44px !important; */
  padding: 8px 18px;
  border-radius: 18px !important;
  background-color: black !important;
  color: white !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  border: none !important;
  cursor: pointer !important;
}

.compile-info {

    width: 37%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    margin-top: 0.5rem;
    align-items: center;
    
}
@media (max-width: 600px) {
  .compile-info {
    width: 100%;
  }
}



.ant-switch.ant-switch-checked {
  background: black !important;
}

.ant-switch {
  background: #E0E0E0 !important;
}

.custom-switch .ant-switch-inner {
  color: white !important; 
}

.ant-switch-inner-unchecked {
  color: #8C8C8C !important;
}
.ant-switch .ant-switch-handle {
  top: 6px !important
}
.sticky-bar {
  width: 100%;
  height: 36px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  position: sticky;
  top: 0;
  z-index: 1000; 
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
}
.num-btn {
width: 40px;
height: 25px;
border-radius: 4px;
background-color: #FFE81E;
display: flex;
justify-content: center;
align-items: center;
}
.num-txt {
  font-family: inter;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-top: 1rem !important;
  margin-bottom: 0.9rem !important;
}
.label-text {
  font-family: inter;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: white;
  margin-top: 1rem !important;
  margin-bottom: 0.9rem !important;
}

.paypal-btn {
  width: 100%;
  height: 50px;
  border-radius: 24px;
  background-color: #FFC43A !important;
}
.known-words-box {
  padding: 8px 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  text-align: center;
  display: inline-block;
}

.new-box {
  /* background-color: transparent; */
  color: #000;
  /* margin-bottom: 12px; */
}

.learn-box {
  background-color: #FCD966;
  color: #000;
  margin-bottom: 12px;
}

.recall-box {
  /* background-color: #ccf9e8; */
  color: #000;
  border: 2px solid #9EF57C;
  margin-bottom: 12px;
}

.known-box {
  background-color: #93C47D;
  color: #000;
  margin-bottom: 12px;
}

.value-box {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}





/* Reset and Center Alignment */
body, html {
  margin: 0;
  padding: 0;
  height: 100%; /* Ensure full viewport height */
}

.sign-left {
  display: flex;           /* Flexbox for inner content */
  flex-direction: column;  /* Stack child elements vertically */
  align-items: center;     /* Center elements horizontally */
  text-align: center;      /* Center-align all text */
  /* background-color: white; Optional: White background for form */
  padding: 20px;           /* Padding for spacing */
  /* border-radius: 8px;      Optional: Rounded corners */
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); Optional: Subtle shadow */
  max-width: 400px;        /* Limit width for cleaner layout */
  width: 90%;              /* Responsive width */
}

.sign-in {
  width: 100%;             /* Ensure full width usage inside .sign-left */
}

.nav-mobile img {
  max-width: 100px;        /* Constrain logo size */
  margin-bottom: 10px;     /* Spacing below logo */
}

.sign-text h2 {
  margin: 10px 0 5px;      /* Space around heading */
  font-size: 24px;         /* Adjust heading size */
}

.sign-text p {
  font-size: 16px;         /* Adjust paragraph size */
  color: #666;             /* Optional: Light text color */
}

.email-area input {
  width: 100%;             /* Full-width inputs */
  padding: 10px;           /* Inner padding for inputs */
  margin: 10px 0;          /* Spacing between inputs */
  border: 1px solid #ddd;  /* Light border */
  border-radius: 4px;      /* Rounded corners for inputs */
  box-sizing: border-box;  /* Include padding in width calculation */
}

.login-btn {
  width: 100%;             /* Full-width button */
  padding: 10px;           /* Inner padding for button */
  background-color: #007bff; /* Button color */
  color: white;            /* Text color */
  border: none;            /* Remove default border */
  border-radius: 4px;      /* Rounded corners */
  cursor: pointer;         /* Pointer cursor for interactivity */
}

.login-btn:hover {
  background-color: #0056b3; /* Darker color on hover */
}

.forgot-p, .login-with button {
  font-size: 14px;         /* Smaller text for additional actions */
  margin-top: 10px;        /* Spacing above actions */
}

.login-with button {
  display: flex;           /* Align text and image horizontally */
  align-items: center;     /* Center content vertically */
  gap: 8px;                /* Space between image and text */
  padding: 10px;           /* Inner padding */
  width: 100%;             /* Full width for responsiveness */
  border: 1px solid #ddd;  /* Subtle border */
  border-radius: 4px;      /* Rounded corners */
  background-color: white; /* White background */
  cursor: pointer;         /* Pointer cursor for buttons */
}



.input-container-center {
  /* display: flex; */
  justify-content: center; /* Center the input container */
  align-items: center;     /* Vertically center the content */
  /* height: 100vh;           Optional: Fill the full height */
}

.input-container-center input[type="number"] {
  text-align: center;      /* Center the cursor and text */
}

.responsive-main-text {
    font-size: 1vw;
    font-weight: 800;
}

.responsive-subtext {
    font-size: 0.8vw;
    font-weight: 400;
}

/* Adjust styles for small screens */
@media (max-width: 768px) {
    .responsive-main-text {
        font-size: 3vw;
    }
    .responsive-subtext {
        font-size: 2.5vw;
    }
}
.membership-card-text{
  font-size: 16px !important;
}

/* Hamburger menu responsive styles */
.hamburger-menu {
  cursor: pointer;
}

.hide-on-desktop {
  display: none;
}

@media screen and (max-width: 768px) {
  .hide-on-desktop {
    display: block;
  }
}

/* Login/Signup page styles */
.google-btn {
  background: white !important;
  border: 1px solid #ddd !important;
  border-radius: 8px !important;
  padding: 10px 20px !important;
  width: 100% !important;
  max-width: 300px !important;
  transition: all 0.2s ease !important;
}

.google-btn:hover {
  background: #f8f8f8 !important;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1) !important;
}

.First-Name input {
  width: 100% !important;
  padding: 12px 15px !important;
  border-radius: 8px !important;
  border: 1px solid #ddd !important;
  font-size: 14px !important;
  transition: border-color 0.2s ease;
}

.First-Name input:focus {
  outline: none;
  border-color: #e89b2f !important;
}

.login-btn {
  width: 100% !important;
  padding: 12px 20px !important;
  border-radius: 8px !important;
  background: #000 !important;
  font-size: 16px !important;
  transition: all 0.2s ease;
}

.login-btn:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

.First-Name input:focus {
  outline: none;
  border-color: #000 !important;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
}

/* Override main section background for auth pages */
.main-section.auth-page {
  background-color: white;
}

.auth-link {
  position: relative;
}

.auth-link:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.2s ease;
}

.auth-link:hover:after {
  transform: scaleX(1);
}

.sign-in input {
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.sign-in input:focus {
  border-color: #e89b2f !important;
  box-shadow: 0 0 0 3px rgba(232, 155, 47, 0.1);
}

.login-btn {
  transition: all 0.3s ease !important;
}

.login-btn:hover {
  background-color: #e89b2f !important;
  border-color: #e89b2f !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(232, 155, 47, 0.2);
}

.google-btn:hover {
  border-color: #e89b2f !important;
  color: #e89b2f !important;
}

.auth-page .sign-left {
  box-shadow: none !important;
}

.remember-me-checkbox {
  accent-color: #e89b2f;
}

.sign-in input, .sign input {
  transition: all 0.2s ease;
}

.sign-in input:focus, .sign input:focus {
  outline: none;
  border-color: #e89b2f !important;
  box-shadow: 0 1px 4px rgba(232, 155, 47, 0.1);
  transform: translateY(-1px);
}

.auth-link {
  transition: color 0.2s ease;
}

.auth-link:hover {
  color: #e89b2f !important;
}

.remember-me-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.remember-me-wrapper input[type="checkbox"] {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  accent-color: #e89b2f;
  cursor: pointer;
}

/* Password Input specific styles */
.password-input {
  position: relative;
  width: 100%;
}

.password-input input:focus {
  border-color: #e89b2f !important;
  box-shadow: 0 1px 4px rgba(232, 155, 47, 0.1);
}

.password-toggle {
  color: #666;
  transition: color 0.2s ease;
}

.password-toggle:hover {
  color: #e89b2f;
}

.password-input input:focus + .password-toggle {
  color: #e89b2f;
}

/* Remove autofill background color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

.sign-text {
  margin-bottom: 1.2rem;
  width: 100%;
  text-align: left;
}

.sign-text h2 {
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  color: #000;
}

.login-with {
  margin-bottom: 0.6rem;
}

.login-with + div {
  margin: 0.6rem 0;
}

.email-area.login-create-inputs {
  gap: 4px !important;
}

.input-label {
  display: block;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  text-align: left;
}

.First-Name {
  margin-bottom: 0.5rem !important;
}

.First-Name input, .password-input input {
  height: 44px !important;
  padding: 12px !important;
  border-radius: 8px !important;
  border: 1px solid #ddd !important;
  font-size: 14px !important;
  width: 100% !important;
  box-sizing: border-box !important;
}

.login-btn {
  margin-top: 0.8rem !important;
}

/* Tighten up the vertical spacing */
.login-with + div {  /* The OR divider */
  margin: 0.8rem 0;
}

.email-area + div {  /* Remember me & Forgot password section */
  margin-top: 0.5rem;
}

/* Make the sign up link closer */
p:last-child {
  margin-top: 0.8rem !important;
}

/* Sign up specific styles */
.sign {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.sign input::placeholder {
  color: #999;
  font-size: 14px;
}

/* Error message styling */
.password-mismatch {
  color: #dc3545;
  font-size: 12px;
  margin: 4px 0 0;
  text-align: left;
}

/* Consistent input box heights */
.sign input, .sign .password-input input {
  height: 44px;
  box-sizing: border-box;
}

/* Password error message */
.password-error {
  color: #dc3545;
  font-size: 12px;
  margin: 4px 0 0;
  padding-left: 2px;
}

/* Button spacing */
.login-btn {
  margin-top: 12px !important;
}

/* Link spacing */
.sign-link {
  margin-top: 12px;
  color: #666;
  font-size: 14px;
}

.sign-link a {
  color: #000;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
}

.sign-link a:hover {
  color: #e89b2f;
}

.page-arrow-icon {
  position: relative;
}

.page-arrow-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.3s ease;
  z-index: -1;
}

.page-arrow-icon:hover::after {
  background-color: rgba(0, 0, 0, 0.1);
}

.arrow-wrapper {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  border-radius: 50%;
}

.arrow-wrapper:hover {
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2));
  transform: scale(1.1);
}

.modal-7-trigger svg {
  transition: transform 0.3s ease;
}

.modal-7-trigger svg:hover {
  transform: scale(1.1);
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2));
}

.modal-7-trigger p {
  margin-top: 5px;
  transition: all 0.3s ease;
}

.modal-7-trigger:hover p {
  transform: translateY(2px);
}
.badges-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #000000;
  margin-bottom: 5px;
}

@media (max-width: 600px) {
  .badges-heading {
    font-size: 16px; 
    margin-bottom: 0 !important;
  }
}

@media (min-width: 700px) and (max-width: 900px) {
  .badges-heading {
    font-size: 20px;
  }
}
.global-definition{
  width: 25%;
}
@media (max-width: 600px) {
  .global-definition {
    width: 100%;
  }
}
.sentence-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid #eee;
}

.sentece-heading {
  font-size: 12px;
  font-weight: 600;
  color: #666;
  margin: 0;
}

.sentence-item {
  padding: 5px;
}

.words {
  width: 45%;
}

.meaning {
  width: 45%;
}

.status {
  width: 10%;
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
  .sentence-container {
    padding: 5px;
    gap: 5px;
  }

  .sentece-heading {
    font-size: 12px;
  }

  .sentence-item {
    padding: 5px 0;
  }

  .words {
    width: 30%;
  }

  .meaning {
    width: 70%;
  }

  .status {
    display: none;
  }

  div[style*="mainBox"] {
    width: 95% !important;
    min-width: unset !important;
    padding: 15px 10px !important;
    margin: 0 !important;
  }
}

.visa-btn{
  background-color:#003087 !important;
  width: 100%;
  height: 50px;
  border-radius: 24px;
}
.debit-btn{
  background-color:#2C2E2F !important;
  width: 100%;
  height: 50px;
  border-radius: 24px;
}

progress.complete::-webkit-progress-value {
  background-color: #34C759;
}

progress.complete::-moz-progress-bar {
  background-color: #34C759;
}

progress.complete {
  color: #34C759;
}
.pageview-content {
  height: 340px;
}

@media (max-width: 600px) {
  .pageview-content {
    height: auto;
  }
}
.modal-trigger-text{
  width: 860px;
  text-align: right;
}
@media (max-width: 600px) {
  .modal-trigger-text {
    width: 100%;
  }
}
 .left-right-arrow{
  gap:40px;
 }
 @media (max-width: 600px) {
  .left-right-arrow {
    gap:10px;
  }
}
.badges-style{
  position: absolute;
  top: 50%;
  left: 16%;
  transform: translate(-50%, -50%);
  font-size: 13px;
  font-weight: bold;
  color: white;
}
@media (max-width: 600px) {
  .badges-style{
    position: absolute;
    top: 50%;
    left: 13%;
    transform: translate(-50%, -50%);
    font-size: 9px;
    font-weight: bold;
    color: white;
  }
}
.welcome-typography{
  font-size: 44px;
}
@media (max-width: 600px) {
  .welcome-typography{
    font-size: 25px;
  }
}
@media (max-width: 600px) {
  .arrow-wrapper{
     margin-top: 15px;
  }
}

@media (max-width: 600px) {
  .paypal-image{
    width: 60px;
  }
}
.payment-buttons{
  width: 50%;
}
@media (max-width: 600px) {
  .payment-buttons{
    width: 100%;
  }
}

@media (max-width: 600px) {
  .debit-card{
    height: 25px;
    }
}
.surah-translation-text{
  display: flex;
  justify-content: center;
}
.surah-arabic-text{
  padding: 0px 32px;
}
.surah-mainayadiv{
  padding: 35px;
}
@media (max-width: 600px) {
  .surah-arabic-text{
    padding: 0px;
    }
    .surah-mainayadiv{
      padding: 0px;
    }
}

.border-bottom-line {
  border-bottom: solid 2px #9ef57c !important;
  padding-bottom: 2px;
}

.Surah-h5-hover {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 5px;
}

.Surah-h5-hover:hover {
  background-color: rgba(51, 143, 255, 0.1);
  transform: scale(1.05);
}

/* Enhanced audio highlighting */
[style*="currentWordIndex"] {
  background-color: rgba(51, 143, 255, 0.15) !important;
  box-shadow: 0 2px 8px rgba(51, 143, 255, 0.2);
  transform: scale(1.05);
}

/* Button group mobile styles */
@media (max-width: 600px) {
  .button-group {
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin-top: 30px;
  }

  .button-group > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .button-group button {
    width: 100%;
    max-width: 300px;
  }

  .play-button {
    order: -1;
  }

  .text-center {
    margin-top: 16px;
  }
}

/* ButtonGroup responsive styles */
@media (min-width: 768px) {
  .button-group {
    flex-direction: row !important;
    align-items: center;
  }
  
  .play-button {
    align-self: auto !important;
  }
}

/* Button group mobile styles */
@media (max-width: 600px) {
  .play-button {
    width: 36px !important;
    height: 36px !important;
    min-width: 36px !important;
  }

  .play-button img {
    width: 20px !important;
    height: 20px !important;
  }
}

/* Arabic buttons */
.MuiButton-root > span:lang(ar),
.MuiButton-root > .MuiButton-startIcon + span,
button:contains("المكتبة") {
  font-family: "Amiri", "Noto Naskh Arabic", serif !important;
}

.fontfamilyuth {
  font-family: uth !important;
}




