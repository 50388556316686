.word-card {
  min-width: 340px;
  border-radius: 30px;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.40);
  padding: 16px;
}

/* Mobile styles */
@media (max-width: 991px) {
  .word-card {
    position: static;
    width: 90%;
    margin: 0 auto;
  }
}

/* Desktop styles */
@media (min-width: 992px) {
  .word-card {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 380px;
    z-index: 9999;
  }
}

.word-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.word-card__close {
  cursor: pointer;
  padding: 4px;
  line-height: 0;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.word-card__close:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transform: scale(1.1);
}

.word-card__meaning-text {
  margin-top: 12px;
  margin-bottom: 0;
  text-align: center;
}

.word-card__meaning-text p {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #338FFF;
}

.word-card__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-top: 16px;
}

.word-card__section h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.word-card__arabic {
  color: #000;
  font-size: 45px;
  font-weight: 500;
  font-family: "uth";
  margin: 0;
}

.word-card__translation {
  font-size: 14px;
  color: #338FFF;
}

.word-card__actions {
  display: flex;
  justify-content: center;
  border-radius: 19px;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.40);
  padding: 18px 24px;
  width: 100%;
  margin-top: 20px;
}

.word-card__action-btn {
  padding: 8px 16px;
  border-radius: 8px;
  min-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.2s ease;
  font-size: 14px;
  font-weight: 500;
}

.word-card__action-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.word-card__action-btn.learning {
  background: #fcd966;
  border: solid 1px #000;
}

.verticalLine {
  width: 2px;
  background-color: #CCCCCC;
  height: 26px;
  margin-top: 4px;
}

@media (max-width: 768px) {
  .word-card {
    position: static;
    width: 90%;
    margin: 0 auto;
  }
}

.word-card-overlay {
  display: none;
}

@media (min-width: 992px) {
  .word-card-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
  }
}

.word-card__success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  text-align: center;
}

.word-card__success p {
  font-size: 18px;
  color: #93C47D;
  margin: 0;
  font-weight: 500;
}

/* Dictionary Modal Styles */
.dictionary-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dictionary-modal {
  background: white;
  border-radius: 8px;
  width: 95%;
  max-width: 1000px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.dictionary-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}

.dictionary-modal-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.dictionary-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.dictionary-close-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dictionary-container {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
}

.dictionary-iframe {
  border: none;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.dictionary-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  color: #4A86E8;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .dictionary-modal {
    width: 95%;
    height: 80vh;
  }
}