.verse-number-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 8px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    transition: all 0.2s ease;
}

.verse-number-container:hover {
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.verse-number {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #666;
    font-weight: 500;
}

.bookmark-icon {
    cursor: pointer;
    font-size: 16px;
    transition: all 0.2s ease;
}

.bookmark-icon:hover {
    transform: scale(1.1);
}

.bookmark-icon.active {
    color: #338FFF;
}

.bookmark-icon.inactive {
    color: #666;
}