.arabic-verse {
  direction: rtl;
}

.arabic-verse__content {
  display: flex;
  text-align: center;
  gap: 8px;
  justify-content: center;
  flex-flow: wrap;
}

.arabic-verse__word-container {
  height: 70px;
  /* margin-bottom: 5px; */
}

.arabic-verse__word {
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.arabic-verse__word:hover {
  background-color: #dbf5fc !important;
  transform: scale(1.05);
  box-shadow: 0 2px 4px rgba(51, 143, 255, 0.4);
}

.arabic-verse__word--familiar {
  border-bottom: solid 2px #9ef57c;
  padding-bottom: 2px;
}

.arabic-verse__word--playing {
  background-color: rgba(51, 143, 255, 0.15) !important;
  transform: scale(1.05);
  box-shadow: 0 2px 8px rgba(51, 143, 255, 0.2);
}

.arabic-verse__text {
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  font-family: "ayat-font";
  margin: 0;
  padding: 6px 10px;
  cursor: pointer;
  font-family: uth !important;
}

.arabic-verse__word--large .arabic-verse__text {
  font-size: 80px;
}

.arabic-verse__translation {
  font-size: 11px;
  word-break: break-word;
  white-space: normal;
  display: flex;
  justify-content: center;
}

.arabic-verse__end-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-bottom: 18px;
}