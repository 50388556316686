.book-card {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  max-width: 600px;
  margin: 20px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.book-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-color: #4CAF50;
}

.book-icon {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.book-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.book-info {
  flex-grow: 1;
}

.book-info h2 {
  margin: 0;
  color: #2C3E50;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Noto Naskh Arabic', serif;
}

.book-info p {
  margin: 8px 0;
  color: #666;
  font-size: 1rem;
  font-family: 'Noto Naskh Arabic', serif;
}

.book-stats {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
  color: #4CAF50;
  font-size: 0.9rem;
  font-weight: 500;
}

.book-stats .dot {
  color: #ccc;
}

.book-arrow {
  color: #4CAF50;
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

.book-card:hover .book-arrow {
  transform: translateX(4px);
}

/* Back button styles */
.back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: #666;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.back-button:hover {
  background: #f5f5f5;
  color: #4CAF50;
}

.back-arrow {
  transform: rotate(180deg);
}

/* Disabled card styles */
.book-card-disabled {
  cursor: default;
  opacity: 0.85;
  background-color: #f8f8f8;
}

.book-card-disabled:hover {
  transform: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-color: #e0e0e0;
}

.coming-soon-badge {
  display: inline-block;
  background-color: #f1c40f;
  color: #333;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-top: 8px;
  font-weight: bold;
}

/* Categories styles */
.book-card-with-categories {
  max-width: 600px;
}

.book-categories {
  margin-top: 15px;
  width: 100%;
}

.book-categories h4 {
  margin: 0 0 10px 0;
  color: #333;
  font-size: 1rem;
  font-weight: 600;
}

.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 10px;
  margin-top: 8px;
}

.category-item {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 8px 12px;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.category-icon {
  color: #4CAF50;
  margin-right: 8px;
  font-size: 0.9rem;
}

.category-info {
  display: flex;
  flex-direction: column;
}

.category-name {
  font-weight: 500;
  font-size: 0.9rem;
  color: #333;
}

.category-count {
  font-size: 0.75rem;
  color: #666;
  margin-top: 2px;
}

/* Section header styles */
.section-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.section-title {
  margin: 0;
  color: #2C3E50;
  font-size: 1.5rem;
  font-weight: 600;
}
